.html{
    
}

.header{
    display: flex;
    justify-content: space-between;
    margin-left: 5px;
    margin-right: 5px;
    border-bottom: 1px solid rgba(228, 228, 228, 0.346);
    box-shadow:  0px 1px 0px rgba(195, 193, 193, 0.228);
    align-items: center;
}

.header-title {
    font-size: 120%;
    font-family: sans-serif;
    font-weight: 200;
}

.logo{
    width: 50px;
    margin-right: 15px;
}