body {
    background-color: lightsteelblue;
}

.vorname {
    font-family: 'Arial';
    font-size: 110%;
}

.nachname {
    font-weight: 500;
    font-style: bold;
    font-size: 120%;
    font-family: 'Arial';
}

